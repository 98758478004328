import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

class Imperial extends Component {
  render() {
    const lang = "pl";
    const data = {
      labels: [
        "Display",
        "Paid Search",
        "Organic Search",
        "Social",
        "Direct",
        "Other",
        "Referral",
      ],
      datasets: [
        {
          label: "Population (millions)",
          data: [42.5, 21.2, 13.9, 8.7, 7.0, 4.8, 2.0],
          backgroundColor: [
            "#058DC7",
            "#4FB331",
            "#ED551B",
            "#EDEF00",
            "#23CBE5",
            "#63E571",
            "#FF9555",
          ],

          hoverBorderColor: "#fff",
        },
      ],
    };
    const nav = {
      initialSlideIndex: 1,
    };
    const { slug } = this.props.pageContext;
    return (
      <Layout
        header={{
          background:
            "linear-gradient( 45deg, #B9A013, #DABD15, #EDCD16, #DABD15, #DABD15 )",
          icons: "#fcda16",
          navClass: "imperial",
        }}
        seo={{
          title: "Imperial Stawowa Residence",
          headerTitle: "imperial",
          href: slug,
          lang: "pl",
          ogImage: require("../../assets/img/portfolio/imperial_top_back.png"),
        }}
        langSwitch={{
          langKey: "en",
          langSlug: "/en/projects/imperial-stawowa-residence/",
        }}
      >
        <PortfolioHeader name="imperial" height="238" />
        <section className="container-fluid imperial_section_2" id="info">
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>
                  Imperial <br /> Stawowa Residence
                </h1>
                <ul>
                  <li>Kampania leadowa</li>
                  <li>Case Study</li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <div className="inner">
                <p>
                  Imperial Capital jest spółką inwestycyjną, realizującą
                  kompleksowo inwestycje mieszkaniowe oraz projekty
                  deweloperskie na krakowskim rynku mieszkaniowym i biurowym.
                  Jedną z inwestycji mieszkaniowych dewelopera jest Stawowa
                  Residence – kameralny kompleks składający się z siedmiu
                  budynków mieszkalnych, zlokalizowanych w Bronowicach Wielkich.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid imperial_section_3">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h4>Problem</h4>
              <p>
                Głównym celem biznesowym kampanii była sprzedaż mieszkań.
                Pobocznym, ale bardzo ważnym, było zbudowanie pozytywnego
                wizerunku marki. Aby skutecznie doprowadzić do sprzedaży
                mieszkań, należało więc zwiększyć świadomość marki wśród osób
                szukających mieszkania do kupna. Wizerunek marki w internecie ma
                wpływ na postrzeganie jej w rzeczywistości, co przekłada się na
                sprzedaż. W październiku 2017 r. zgłosił się do nas Klient, z
                prośbą o wsparcie sprzedaży płatnymi kampaniami.
              </p>

              <h4>Rozwiązanie - klasyczny lejek sprzedażowy</h4>
              <p>
                Skrupulatnie przeanalizowaliśmy przesłany przez Klienta brief.
                Zastanawialiśmy się nad strategią działań, wyłapaniem
                najważniejszych fraz kluczowych oraz przedstawieniem korzyści i
                elementów przewagi inwestycji nad innymi, podobnymi, które
                oferowane są na rynku krakowskim.
              </p>
              <p>
                Skupiliśmy się na kampaniach kierowanych na sieć wyszukiwania,
                zawierających reklamy z frazami związanymi z kupnem mieszkania
                oraz frazami brandowymi. Ponadto, stworzyliśmy kampanię
                kierowaną na sieć reklamową, obejmującą banery oraz reklamy
                elastyczne. Od razu utworzyliśmy listy remarketingowe, dzięki
                którym, po osiągnięciu odpowiedniego rozmiaru listy, mogliśmy
                kierować reklamy z precyzyjniejszym komunikatem do użytkowników,
                którzy wg określonych reguł weszli w reakcję z witryną Klienta.
              </p>
              <p>
                Plan był taki – poprzez zwiększenie świadomości i
                rozpoznawalności marki oraz przejście kolejnych etapów lejka
                sprzedażowego – dotrzeć do momentu wyprzedania mieszkań ze
                wszystkich 3 etapów inwestycji.
              </p>
              <p>
                Na początku tworzyliśmy szeroki komunikat, informując
                użytkowników (w określonej grupie wiekowej oraz znajdujących się
                w określonej lokalizacji) o istnieniu inwestycji, zwiększając
                świadomość o marce oraz głosząc pozytywny flow brandu. Przekaz
                miał wzbudzić zainteresowanie, zaintrygować. Tu stosowaliśmy
                kampanię GDN (z wykorzystaniem banerów statycznych oraz
                dynamicznych [zobacz nasze przykłady tu{" "}
                <a href="https://when.pl/uslugi/banery-remarketingowe/">
                  https://when.pl/uslugi/banery-remarketingowe/
                </a>
                ] i reklamę elastyczną), wyświetlanych w sieci reklamowej
                Google. Użycie baneru dynamicznego z animowanymi, zmieniającymi
                się grafikami – jest świetnym uzupełnieniem statycznych reklam.
                Te kreacje kierowaliśmy do użytkowników skłonnych kupić
                mieszkanie, interesujących się inwestycjami czy hipoteką.
                Ponadto, powstała kampania w sieci wyszukiwania z wykorzystaniem
                rozszerzonych reklam tekstowych. Na tym etapie użytkownicy
                poszukiwali informacji raczej ogólnych, wpisując frazy ogólne
                związane z inwestycją, typu deweloper krakow czy kupie
                mieszkanie od dewelopera. Kolejnym krokiem na ścieżce zakupowej
                jest rozważanie zakupu. Kupić czy nie kupować tego mieszkania?
                Jakie mieszkanie kupić?
              </p>
              <p>
                Zastosowaliśmy remarketing, dzięki któremu docieraliśmy do osób,
                które w pewnym stopniu były już “związane” z inwestycją.
                Użytkownicy przeszukiwali witrynę, spędzili tam pewien czas,
                ściągnęli rzut mieszkania w pdf, skonsultowali się z doradcą, a
                może nawet odwiedzili teren inwestycji. Dodatkowym kryterium,
                jakie wzięliśmy pod uwagę, był czas spędzony na stronie oraz
                liczba obejrzanych podstron. Te wskaźniki świadczą o poziomie
                zaangażowaniu użytkownika.
              </p>
              <p>
                Remarketing w branży deweloperskiej działa niezwykle silnie, jak
                wiemy, proces zakupowy mieszkania nie trwa kilka dni. Wymaga on
                najczęściej wielu przemyśleń, uzgodnień, formalności itp., zatem
                docieranie do takiego wyselekcjonowanego już użytkownika z
                subtelnym i spersonalizowanym komunikatem (np. informującym o
                rozpoczęciu sprzedaży III etapu, czy promocji na nie mieszkania
                z etapu I) może przyspieszyć jego decyzję o zakupie mieszkania.
              </p>
              <p>
                Przez cały czas wspomagania Klienta promocją Google Ads
                optymalizowaliśmy kampanię, eliminując nietrafione wyszukiwania
                (wykluczające frazy kluczowe), na bieżąco aktualizowaliśmy
                informacje, zmienialiśmy kreacje reklamowe, przeprowadzaliśmy
                liczne testy A/B, a wszystko po to, żeby rzetelnie dotrzeć z
                komunikatem do najbardziej zainteresowanego kupnem mieszkania
                użytkownika.
              </p>

              <h4>Efekty</h4>
              <p>
                Efektem naszej pracy było zbudowanie silnego wizerunku dookoła
                marki, wzbudzenie zainteresowania użytkowników i wsparcie
                sprzedaży mieszkań w inwestycji Stawowa Residence kampanią
                Google Ads.
              </p>
              <p>
                Poniżej przedstawiamy kanały, dzięki którym użytkownicy trafili
                na witrynę imperialstawowa.pl. Prym wiedzie Google Ads – głównie
                kampania w sieci reklamowej (reklama elastyczna oraz banerowa),
                stanowiąca ponad 42% generowanego ruchu. Kampania w sieci
                wyszukiwania stanowi ponad 21% całości ruchu.
              </p>

              <div className="canvas_wrapper">
                <Pie data={data} />
              </div>
              <TrackVisibility once offset={300}>
                {({ isVisible }) => (
                  <>
                    <h4>Kluczowe wskźniki sukcesu</h4>
                    <ul>
                      <li>
                        nasze reklamy zostały wyświetlone{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          decimals={1}
                          end={isVisible ? 27.2 : 0}
                        />{" "}
                        mln razy
                      </li>
                      <li>
                        nasze reklamy zostały kliknięte{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          decimals={1}
                          end={isVisible ? 81.1 : 0}
                        />{" "}
                        tys razy, powodując przejście do witryny inwestycji
                      </li>
                      <li>
                        średni koszt konwersji wyniósł{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          decimals={2}
                          end={isVisible ? 15.98 : 0}
                        />{" "}
                        zł
                      </li>
                      <li>
                        średni koszt za kliknięcie wyniósł{" "}
                        <CountUp
                          start={0}
                          duration={3}
                          decimals={2}
                          end={isVisible ? 0.72 : 0}
                        />{" "}
                        zł
                      </li>
                    </ul>
                    <p>
                      Przez cały okres działań marketingowych udało nam się
                      uzyskać m.in.:
                    </p>
                    <ul>
                      <li>
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 1966 : 0}
                        />{" "}
                        kliknięć w zakładkę kontakt
                      </li>
                      <li>
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 1772 : 0}
                        />{" "}
                        pobrań pdf mieszkań
                      </li>
                      <li>
                        <CountUp
                          start={0}
                          duration={3}
                          end={isVisible ? 49 : 0}
                        />{" "}
                        wysłanych zapytań przez formularz
                      </li>
                    </ul>
                    <p>
                      Na chwilę obecną, ze{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 112 : 0}
                      />{" "}
                      mieszkań, sprzedano{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 104 : 0}
                      />
                      , co stanowi{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        decimals={1}
                        end={isVisible ? 92.8 : 0}
                      />{" "}
                      sprzedanych nieruchomości ze wszystkich trzech etapów.
                      Naszym celem jest zamknięcie sprzedaży na poziomie{" "}
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                      %.
                    </p>
                  </>
                )}
              </TrackVisibility>
            </div>
          </div>
        </section>
        <PortfolioNav data={nav} locales={locales[lang]} />
      </Layout>
    );
  }
}

export default Imperial;
